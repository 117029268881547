function formatDollarAmount(amount) {
  if (amount >= 1000000) {
    amount = (amount / 1000000).toFixed(2);
    if (amount.endsWith(".00")) {
      amount = amount.slice(0, -3);
    } else if (amount.endsWith("0")) {
      amount = amount.slice(0, -1);
    }
    return `$${amount}m`;
  } else if (amount >= 1000) {
    amount = (amount / 1000).toFixed(1);
    if (amount.endsWith(".0")) {
      amount = amount.slice(0, -2);
    }
    return `$${amount}k`;
  } else {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(amount);
  }
}

function doesCardExist(card, winningCards) {
  if (card && winningCards) {
    let suitLower = card?.slice(-1).toLowerCase();
    let rankLower = card?.slice(0, -1).toLowerCase();
    return winningCards[0]?.find(
      (card) =>
        card.value.toLowerCase() === rankLower &&
        card.suit.toLowerCase() === suitLower
    );
  }
}

function areArraysEqual(arr1, arr2) {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

function adjustFoldedPlayerLocation(player, i) {
  if (!player?.inGame) {
    if (i === 1) {
      return { left: "6%" };
    } else if (i === 2) {
      return { left: "8%" };
    } else if (i === 4) {
      return { right: "8%" };
    } else if (i === 5) {
      return { right: "6%" };
    }
  }

  return "";
}

const getImageUrl = (card) => {
  let suitLower = card.slice(-1).toLowerCase();
  let rankLower = card.slice(0, -1).toLowerCase();

  //find card suit
  if (suitLower === "d") {
    suitLower = "diamonds";
  } else if (suitLower === "s") {
    suitLower = "spades";
  } else if (suitLower === "c") {
    suitLower = "clubs";
  } else if (suitLower === "h") {
    suitLower = "hearts";
  }

  //find card rank
  if (rankLower === "j") {
    rankLower = "jack";
  } else if (rankLower === "q") {
    rankLower = "queen";
  } else if (rankLower === "k") {
    rankLower = "king";
  } else if (rankLower === "t") {
    rankLower = "ten";
  } else if (rankLower === "a") {
    rankLower = "ace";
  }

  // Generate the file name based on rank and suit
  const fileName = `${rankLower}-of-${suitLower}.png`;
  return require(`../components/assets/cards/${fileName}`);
};

function rotateLeftByN(arr, n) {
  const len = arr.length;
  if (len === 0) return arr;
  n = n % len;
  return arr.slice(n).concat(arr.slice(0, n));
}

function findPlayerIndexById(game, socketID) {
  return game.players.findIndex((player) => player.socketID === socketID);
}

function findWinnerById(game, playerID) {
  return game.players.findIndex((player) => player.id === playerID);
}

function doesPlayerExist(game, socketID) {
  if (game && game.players) {
    return game.players.some((player) => player.socketID === socketID);
  }
  return false;
}

module.exports = {
  formatDollarAmount,
  getImageUrl,
  rotateLeftByN,
  findPlayerIndexById,
  findWinnerById,
  doesPlayerExist,
  doesCardExist,
  adjustFoldedPlayerLocation,
  areArraysEqual
};
