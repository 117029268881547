import React from "react";
import { Box, Card } from "@mui/material";
import { styled } from "@mui/system";

import backofcard from "../assets/cards/back.png";

const { getImageUrl, doesCardExist } = require("../../functions/common");

const FlipCard = styled(Box)(({ flipped, cardIndex, player, playerId }) => ({
  backgroundColor: "transparent",
  display: "flex",
  position: "absolute",
  perspective: "1000px",
  "& .flipCardInner": {
    transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
  },
  transform: cardIndex === 0 ? "rotate(-15deg)" : "rotate(15deg)",

  left: (() => {
    if (cardIndex === 0) {
      if (playerId === player?.id) {
        return "7%";
      } else {
        return "10%";
      }
    }

    if (cardIndex === 1) {
      if (playerId === player?.id) {
        return "40%";
      } else {
        if (flipped) {
          return "40%";
        } else {
          return "20%";
        }
      }
    }
  })(),

  top: (() => {
    if (cardIndex === 0) {
      if (playerId === player?.id) {
        return "10%";
      } else {
        if (flipped) {
          return "6%";
        } else {
          return "16%";
        }
      }
    }

    if (cardIndex === 1) {
      if (playerId === player?.id) {
        return "10%";
      } else {
        if (flipped) {
          return "6%";
        } else {
          return "16%";
        }
      }
    }

    // Fallback case if none of the conditions are met
    return "0%";
  })(),
}));

const FlipCardInner = styled(Box)(({ cardIndex, player, playerId }) => ({
  display: "flex",
  position: "relative",
  width: "100%",
  height: "100%",
  textAlign: "center",
  transition: "transform 0.6s",
  transformStyle: "preserve-3d",
}));

const FlipCardFront = styled(Card)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden",
  backgroundColor: "transparent",
  elevation: 0,
  boxShadow: "none",
}));

const FlipCardBack = styled(Card)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden",
  backgroundColor: "transparent",
  transform: "rotateY(180deg)",
  elevation: 0,
  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
}));

const WinningCards = styled(Card)(({ game, card, player, playerId }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundColor: doesCardExist(card, game?.winner?.winningCards)
    ? "#FFFF0030"
    : game?.winner?.winningCards
    ? "#00000050"
    : "transparent",
  border:
    doesCardExist(card, game?.winner?.winningCards) && "0.2vw solid #FFFF00",
  borderRadius: playerId === player?.id ? "0.5vw" : "0.4vw",
  elevation: 0,
  boxShadow: "none",
}));

const FoldedCards = styled(Card)(({ game, card, player, playerId }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  display: playerId === player?.id && !player.inGame ? "flex" : "none",
  backgroundColor: "#00000050",
  elevation: 0,
  boxShadow: "none",
}));

const FlipCardComponent = ({
  flipped,
  game,
  card,
  cardIndex,
  player,
  playerId,
}) => {
  let cardHeight;
  let cardWidth;

  if (game?.advanceToShowDown || game?.winner?.showCards) {
    cardHeight = playerId === player?.id ? "14vh" : "14vh";
    cardWidth = playerId === player?.id ? "5vw" : "5vw";
  } else {
    cardHeight = playerId === player?.id ? "14vh" : "9vh";
    cardWidth = playerId === player?.id ? "5vw" : "3.2vw";
  }

  return (
    <Box
      textAlign="center"
      style={{
        boxShadow: "none",
        display: playerId !== player?.id && !player.inGame ? "none" : "flex",
      }}
    >
      <FlipCard
        cardIndex={cardIndex}
        player={player}
        playerId={playerId}
        flipped={
          playerId === player?.id
            ? flipped
            : game?.advanceToShowDown || game?.winner?.showCards
            ? true
            : false
        }
        style={{
          width: cardWidth,
          height: cardHeight,
        }}
      >
        <FlipCardInner
          className="flipCardInner"
          cardIndex={cardIndex}
          player={player}
          playerId={playerId}
          flipped={flipped}
        >
          <FlipCardFront>
            <img
              src={backofcard}
              alt="card"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </FlipCardFront>
          <FlipCardBack
            style={{
              display:
                playerId === player?.id
                  ? "flex"
                  : game?.advanceToShowDown || game?.winner?.showCards
                  ? "flex"
                  : "none",
            }}
          >
            <FoldedCards
              game={game}
              card={card}
              player={player}
              playerId={playerId}
            />
            <WinningCards
              game={game}
              card={card}
              player={player}
              playerId={playerId}
            />
            <img
              src={getImageUrl(card)}
              alt="Avatar"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </FlipCardBack>
        </FlipCardInner>
      </FlipCard>
    </Box>
  );
};

export default FlipCardComponent;
