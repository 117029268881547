import { makeStyles } from "@material-ui/core/styles";
import wallpaper from "./components/assets/table/wallpaper.png";
import tableImage from "./components/assets/table/Table.png";

const useStyles = makeStyles((theme) => ({
  wallpaper: {
    position: "relative",
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${wallpaper})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "black",
  },
  table: {
    position: "absolute",
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${tableImage})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    bottom: "3%",
  },
  playerContainer: {
    position: "absolute",
    width: "20vw",
    height: "20vh",
    display: "flex",
    alignItems: "center",
  },
  playerInformation: {
    backgroundColor: "#2d2d2d",
    color: "white",
    borderRadius: "2vh",
    width: "11vw",
    height: "50%",
    border: "2px solid #3498db",
  },
  playerAvatarContainer: {
    position: "absolute",
    overflow: "hidden",
    right: "0%",
    width: "50%",
    height: "100%",
    borderRadius: "50%",
    border: "2px solid #3498db",
    backgroundColor: "black",
  },
  playerAvatar: {
    backgroundColor: "#3498db50",
    width: "100%",
    height: "100%",
    padding: "4vh",
  },
  playerCards: {
    display: "flex",
    position: "absolute",
    overflow: "hidden",
  },
  player1: {
    bottom: "4%",
    right: "44%",
  },
  player2: {
    bottom: "20%",
    left: "5%",
  },
  player3: {
    top: "20%",
    left: "5%",
  },
  player4: {
    top: "6%",
    right: "44%",
  },
  player5: {
    top: "20%",
    right: "5%",
  },
  player6: {
    bottom: "20%",
    right: "5%",
  },
  timer1: {
    top: "-4%",
    right: "-20%",
  },
  timer2: {
    top: "-10%",
    left: "40%",
  },
  timer3: {
    bottom: "-10%",
    left: "40%",
  },
  timer4: {
    bottom: "-10%",
    left: "40%",
  },
  timer5: {
    bottom: "-10%",
    left: "45%",
  },
  timer6: {
    top: "-10%",
    left: "45%",
  },
  dealerChipContainer: {
    backgroundColor: "white",
    height: "5vh",
    width: "2vw",
    borderRadius: "50%",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dealer1: {
    left: "43%",
    bottom: "25%",
  },
  dealer2: {
    left: "29.5%",
    bottom: "29%",
  },
  dealer3: {
    left: "26%",
    top: "43%",
  },
  dealer4: {
    left: "43%",
    top: "29%",
  },
  dealer5: {
    right: "26%",
    top: "43%",
  },
  dealer6: {
    right: "29.5%",
    bottom: "29%",
  },
  playerActionContainer: {
    backgroundColor: "#2d2d2d",
    height: "5vh",
    width: "8vw",
    borderRadius: "8vh",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  playerCallIcon: {
    display: "flex",
    color: "white",
    width: "2vw",
    height: "5vh",
    borderRadius: "50%",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  playerAmountLabel: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    width: "6vw",
    paddingRight: "1vh",
  },
  playerAction1: {
    right: "45%",
    bottom: "25%",
  },
  playerAction2: {
    left: "26%",
    bottom: "36%",
  },
  playerAction3: {
    left: "28%",
    top: "38%",
  },
  playerAction4: {
    right: "45%",
    top: "29%",
  },
  playerAction5: {
    right: "28%",
    top: "38%",
  },
  playerAction6: {
    right: "26%",
    bottom: "36%",
  },
  smallBlindChip: {},
  bigBlindChip: {},
  potContainer: {
    position: "absolute",
    display: "flex",
    top: "38%",
    left: "27%",
    justifyContent: "center",
    alignItems: "center",
    width: "45vw",
    gap: "1vw",
  },
  pot: {
    backgroundColor: "#00000070",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5vh 0.5vw",
    borderRadius: "1vh",
    gap: "0.5vw",
  },
  animatedPot: {
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    backgroundColor: "#00000070",
    padding: "0.5vh 0.5vw",
    borderRadius: "1vh",
    color: "white",
    gap: "0.5vw",
  },
  community: {
    position: "absolute",
    top: "45%",
    left: "35%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: "6.5vw",
  },
  actionContainer: {
    position: "absolute",
    right: "1%",
    bottom: "5%",
    backgroundSize: "cover",
    backgroundColor: "#ffffff50",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "2vh 1vw",
    borderRadius: "2vh",
  },
  playerRaiseContainer: {
    position: "absolute",
    right: "3%",
    bottom: "20%",
    height: "50vh",
    backgroundColor: "#ffffff50",
    display: "flex",
    padding: "2vh 1vw",
    borderRadius: "2vh",
  },
  winnerContainer: {
    position: "absolute",
    display: "flex",
    top: "32%",
    left: "33%",
    justifyContent: "center",
    alignItems: "center",
    width: "35vw",
    backgroundColor: "#00000070",
    gap: "1vw",
    padding: "0.5vh 0.5vw",
    borderRadius: "1vh",
    color: "white",
  },
  winner: {
    display: "flex",
  },
}));

export default useStyles;
