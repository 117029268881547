import React from "react";
import PokerTable from "./components/pokerTable";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins, sans-serif", // Specify your custom font stack with Poppins
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Ensures correct styling */}
      <PokerTable />
    </ThemeProvider>
  );
};

export default App;