import React, { useEffect, useState, useRef } from "react";
import { Howl, Howler } from "howler";
import { useSpring, animated } from "@react-spring/web";

/* MATERIAL UI */
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";

import useStyles from "../useStyles"; // Update with the correct path

/* ASSETS */
import pokerChip from "./assets/table/chip.png";
import Gorilla from "./assets/avatars/Gorilla.png";
import Bear from "./assets/avatars/Bear.png";
import Cat from "./assets/avatars/Cat.png";
import Crocodile from "./assets/avatars/Crocodile.png";
import Dog from "./assets/avatars/Dog.png";
import Giraffe from "./assets/avatars/Giraffe.png";

/* SOUND EFFECTS */
import check from "../components/assets/sounds/check.mp3";
import call from "../components/assets/sounds/call.mp3";
import raise from "../components/assets/sounds/raise.mp3";
import all_in from "../components/assets/sounds/all-in.mp3";
import ding from "../components/assets/sounds/ding.mp3";
import cheering from "../components/assets/sounds/cheering.mp3";
import applause from "../components/assets/sounds/applause.mp3";
import card_flip from "../components/assets/sounds/card-flip.mp3";
import crowd_gasp from "../components/assets/sounds/crowd-gasp.mp3";
import shuffle_cards from "../components/assets/sounds/shuffle.mp3";

/* Components */
import PlayerActionUI from "./player/PlayerActionUI";
import FlippableCard from "./cards/FlippableCard";
import FlippableCommunityCard from "./cards/FlippableCommunityCard";
import Slider from "./slider/NoUISlider";

/* AXIOS */
import axiosConfig from "../axiosConfig";
import io from "socket.io-client";
const socket = io(process.env.REACT_APP_SOCKET_URL);

const {
  formatDollarAmount,
  getImageUrl,
  rotateLeftByN,
  findPlayerIndexById,
  findWinnerById,
  doesPlayerExist,
  doesCardExist,
  adjustFoldedPlayerLocation,
  areArraysEqual,
} = require("../functions/common");

const sounds = {
  check: new Howl({ src: [check], html5: false }),
  call: new Howl({ src: [call], html5: false }),
  raise: new Howl({ src: [raise], html5: false }),
  all_in: new Howl({ src: [all_in], html5: false }),
  ding: new Howl({ src: [ding], html5: false, volume: 0.2 }),
  cheering: new Howl({ src: [cheering], html5: false }),
  applause: new Howl({ src: [applause], html5: false }),
  card_flip: new Howl({ src: [card_flip], html5: false }),
  crowd_gasp: new Howl({ src: [crowd_gasp], html5: false }),
  shuffle_cards: new Howl({ src: [shuffle_cards], html5: false }),
};

export default function PokerTable() {
  const classes = useStyles();

  const [showJoinDialog, setShowJoinDialog] = React.useState(false);
  const [playerIsJoining, setPlayerIsJoining] = useState(false);

  const [playerName, setPlayerName] = useState("");
  const [playerId, setPlayerId] = useState(null);

  const [game, setGame] = useState(null);

  const [currentPlayerTurn, setCurrentPlayerTurn] = useState(null);

  const [showBetBar, setShowBetBar] = useState(false);
  const [showRaiseBar, setShowRaiseBar] = useState(false);

  const [sliderAmount, setSliderAmount] = useState(0);
  const [gameVolumeAmount, setGameVolumeAmount] = useState(1.0);

  const userAvatars = [Gorilla, Bear, Cat, Crocodile, Dog, Giraffe];

  const [shouldReconnectPlayer, setShouldReconnectPlayer] = useState(false);

  const [gameWinnerID, setGameWinnerID] = useState(null);

  const [flipped, setFlipped] = useState(false);

  const [playerTimeLeft, setPlayerTimeLeft] = React.useState(20);

  const positions = {
    0: { top: "84%", left: "48%" },
    1: { top: "68%", left: "17%" },
    2: { top: "28%", left: "17%" },
    3: { top: "14%", left: "48%" },
    4: { top: "28%", left: "77%" },
    5: { top: "68%", left: "77%" },
  };

  //spring config
  const [springs, api] = useSpring(() => ({
    from: { top: "38%", left: "47%" },
    config: { duration: 1000 },
  }));

  const moveSidePotToWinner = (game, winnerId) => {
    let playerWinnerIndex = findWinnerById(game, winnerId);
    const newPosition = positions[playerWinnerIndex] || {};

    api.start({
      from: {
        top: "38%",
        left: "47%",
      },
      to: {
        ...newPosition,
      },
    });
  };

  const stopAllSounds = () => {
    Object.values(sounds).forEach((sound) => {
      sound.stop();
    });
  };

  /* works with all 3 methods audio context, true and false */
  const playSilentAudio = () => {
    // Create the AudioContext
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();

    // Create an oscillator
    const oscillator = audioContext.createOscillator();

    // Create a GainNode for volume control
    const gainNode = audioContext.createGain();

    // Connect the oscillator to the gain node
    oscillator.connect(gainNode);

    // Connect the gain node to the destination (speakers)
    gainNode.connect(audioContext.destination);

    // Set the volume (gain value). 1 is the default volume, 0 is silence.
    gainNode.gain.value = 0; // Set to 50% volume

    // Start and stop the oscillator
    oscillator.start();
    oscillator.stop(audioContext.currentTime + 0.1);

    /*
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    oscillator.connect(audioContext.destination);
    oscillator.start();
    oscillator.stop(audioContext.currentTime + 0.1);
    */

    //const silentSound1 = new Howl({ src: [check], volume: 0, html5: true });
    //silentSound1.play();

    //const silentSound2 = new Howl({ src: [check], volume: 0, html5: false });
    //silentSound2.play();
  };

  const handleUserInteraction = () => {
    // Play a silent sound to enable audio playback
    sounds.check.play();
    sounds.check.stop();

    // Remove event listener after initial interaction
    window.removeEventListener("touchstart", handleUserInteraction);
    window.removeEventListener("click", handleUserInteraction);
  };

  useEffect(() => {
    // Add touchstart event listener for iOS devices
    window.addEventListener("touchstart", handleUserInteraction);
    window.addEventListener("click", handleUserInteraction);
  }, []);

  useEffect(() => {
    socket.on("gameState", (gameState) => {
      console.log("gameState: ", gameState);

      if (gameState?.gameStarted) {
        //organize players here before setting game:
        const playerIndex = findPlayerIndexById(gameState, socket.id);
        console.log("index: ", playerIndex);

        //rotate by that amount to the left
        const orderedPlayers = rotateLeftByN(gameState.players, playerIndex);
        console.log("orderedPlayers: ", orderedPlayers);

        const updatedGame = { ...gameState, players: orderedPlayers };
        setGame(updatedGame);

        setCurrentPlayerTurn(gameState.players[gameState.currentPlayerIndex]);
      } else {
        setGame(gameState);
      }

      //reset slider value
      setShowRaiseBar(false);
      setShowBetBar(false);
    });

    socket.on("connect", () => {
      //when a new connection is established, update the existing socketID if any
      setShouldReconnectPlayer(true);
    });

    socket.on("disconnect", () => {
      //if a player disconnects...
    });

    socket.on("playerActionSound", (action) => {
      switch (action) {
        case "check":
          sounds.check.play();
          break;
        case "call":
          sounds.call.play();
          break;
        case "bet":
        case "raise":
          sounds.raise.play();
          break;
        case "all-in":
          sounds.all_in.play();
          sounds.crowd_gasp.play();
          break;
        default:
          return;
      }
    });

    socket.on("cardDealt", () => {
      sounds.card_flip.play();
    });

    socket.on("shuffledCards", () => {
      //stopAllSounds();
      sounds.shuffle_cards.play();
    });

    socket.on("flipUserCard", (flipUserCard) => {
      setFlipped(flipUserCard);
    });

    return () => {
      socket.off("gameState");
      socket.off("disconnect");
      socket.off("reconnect");
      socket.off("connect");
      socket.off("playerActionSound");
      socket.off("currentPlayerTurnSound");
      socket.off("cardDealt");
      socket.off("flipUserCard");
      socket.off("shuffledCards");
    };
  }, []);

  useEffect(() => {
    if (game?.gameStarted && game?.winner && game?.sidePots.length > 0) {
      moveSidePotToWinner(game, game?.winner?.playerIds[0]);
    }
  }, [game]);

  useEffect(() => {
    if (shouldReconnectPlayer) {
      socket.emit("reconnect", playerId);
      setShouldReconnectPlayer(false);
    }
  }, [shouldReconnectPlayer]);

  useEffect(() => {
    console.log("game.stage: ", game?.stage);
    console.log("game.communityCards: ", game?.communityCards?.length);

    setPlayerTimeLeft(20);

    if (
      !game?.winner &&
      !game?.advanceToShowDown &&
      flipped &&
      currentPlayerTurn?.id === playerId
    ) {
      if (game?.stage === "flop") {
        if (game?.communityCards?.length === 3) {
          console.log("play sound 1");
          sounds.ding.play();
        }
      } else {
        console.log("play sound 2");
        sounds.ding.play();
      }
    }
  }, [currentPlayerTurn]);

  useEffect(() => {
    if (game?.winner) {
      if (!areArraysEqual(game?.winner?.playerIds, gameWinnerID)) {
        setGameWinnerID(game?.winner?.playerIds);
      }
    } else {
      setGameWinnerID(null);
    }
  }, [game]);

  useEffect(() => {
    if (gameWinnerID) {
      if (game?.winner?.playerIds?.includes(playerId)) {
        sounds.cheering.play();
      } else {
        sounds.applause.play();
      }
    }
  }, [gameWinnerID]);

  const playerTimeLeftPercentage = (playerTimeLeft / 20) * 100;

  useEffect(() => {
    const timer = setInterval(() => {
      setPlayerTimeLeft((prevProgress) => {
        if (prevProgress > 1) {
          return prevProgress - 1;
        }
        return 0;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [currentPlayerTurn]); // game?.isDealing

  const joinGame = () => {
    const id = Math.floor(Math.random() * 10000); // Simple random ID for demo
    setPlayerId(id);
    setShowJoinDialog(false);
    setPlayerIsJoining(false);

    let chipAmount = 1000000;

    if (playerName === "player 2") {
      chipAmount = 50000;
    } else if (playerName === "player 3") {
      chipAmount = 100000;
    } else if (playerName === "player 4") {
      chipAmount = 150000;
    } else if (playerName === "player 5") {
      chipAmount = 200000;
    } else if (playerName === "player 6") {
      chipAmount = 250000;
    }

    socket.emit("joinGame", { id, name: playerName, chips: chipAmount });
  };

  const startGame = () => {
    socket.emit("startGame");
  };

  const leaveGame = () => {
    socket.emit("leaveGame", playerId);
  };

  const playerAction = (action, amount) => {
    axiosConfig
      .post("/action", {
        playerId: playerId,
        action: action,
        amount: amount,
      })
      .then((response) => {
        //console.log(response.data);
      })
      .catch((error) => {
        //console.error(error.response.data);
      });
  };

  useEffect(() => {
    console.log("slideramount: ", sliderAmount);
  }, [sliderAmount]);

  const handleRaiseValueChange = (value) => {
    setSliderAmount(value);
  };

  const displayTable = () => {
    return (
      <>
        <Dialog
          open={showJoinDialog}
          onClose={() => {
            setShowJoinDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Enter your name</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={playerIsJoining}
              onClick={() => {
                setShowJoinDialog(false);
              }}
            >
              Close
            </Button>
            <Button
              disabled={playerIsJoining || playerName.length < 3}
              onClick={() => {
                setPlayerIsJoining(true);
                playSilentAudio();
                joinGame();
              }}
              autoFocus
            >
              Join
              {playerIsJoining && <CircularProgress />}
            </Button>
          </DialogActions>
        </Dialog>
        <Box className={classes.wallpaper}>
          <Box className={classes.table}>
            {game?.players.map((player, i) => (
              <>
                <Box
                  className={`${classes.playerContainer} ${
                    classes[`player${i + 1}`]
                  }`}
                  style={{
                    flexDirection: i > 3 && "row-reverse",
                    ...adjustFoldedPlayerLocation(player, i),
                    opacity:
                      playerId !== player?.id && !player?.inGame ? "0.5" : "1",
                    scale:
                      playerId !== player?.id && !player?.inGame ? "0.7" : "1",
                  }}
                  key={i}
                >
                  {/* Player Information */}
                  <Box className={classes.playerInformation}>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "3vh",
                        textAlign: "center",
                      }}
                    >
                      {player.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontSize: "3vh",
                        textAlign: "center",
                      }}
                    >
                      {player.isAllIn
                        ? "ALL IN"
                        : formatDollarAmount(player.chips)}
                    </Typography>
                  </Box>
                  <Box
                    className={classes.playerAvatarContainer}
                    style={{
                      left: i > 3 && "0%",
                      boxShadow: game?.winner?.playerIds?.includes(player.id)
                        ? "0px 0px 50px yellow"
                        : !game?.winner &&
                          !game?.advanceToShowDown &&
                          currentPlayerTurn?.id === player.id &&
                          "0px 0px 30px white",
                    }}
                  >
                    <img
                      className={classes.playerAvatar}
                      src={userAvatars[i]}
                    />
                  </Box>

                  <Box
                    className={`${classes[`timer${i + 1}`]}`}
                    sx={{
                      position: "absolute",
                      display:
                        !game?.winner &&
                        !game?.advanceToShowDown &&
                        game?.gameStarted &&
                        player.id === currentPlayerTurn?.id &&
                        !game?.isDealing
                          ? "inline-flex"
                          : "none",
                    }}
                  >
                    <CircularProgress
                      style={{
                        backgroundColor: "#2d2d2d",
                        color: "#3498db",
                        borderRadius: "50%",
                      }}
                      variant="determinate"
                      value={playerTimeLeftPercentage}
                      size={"6vh"}
                    />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          fontSize: "3vh",
                          textAlign: "center",
                          color: "#3498db",
                        }}
                      >
                        {playerTimeLeft}
                      </Typography>
                    </Box>
                  </Box>
                  {/* Player Information */}
                  {/* Player Cards */}
                  <Box
                    className={classes.playerCards}
                    style={{
                      width: playerId === player.id ? "12vw" : "10vw",
                      height: playerId === player.id ? "14vh" : "20vh",

                      left: (() => {
                        if (
                          !game?.advanceToShowDown &&
                          !game?.winner?.showCards
                        ) {
                          return i === 0 && playerId === player.id
                            ? "48%"
                            : i < 4
                            ? "90%"
                            : "-10%";
                        } else if (
                          game?.advanceToShowDown ||
                          game?.winner?.showCards
                        ) {
                          return i === 0 && playerId === player.id
                            ? "50%"
                            : i < 4
                            ? "52%"
                            : "1%";
                        }
                        // Fallback case if none of the conditions are met
                        return "0%";
                      })(),
                      top: (() => {
                        if (
                          !game?.advanceToShowDown &&
                          !game?.winner?.showCards
                        ) {
                          return i === 0 ? "35%" : "30%";
                        } else if (
                          game?.advanceToShowDown ||
                          game?.winner?.showCards
                        ) {
                          return i === 0 ? "35%" : "12%";
                        }
                      })(),
                    }}
                  >
                    {player.hand.map((card, cardIndex) => (
                      <>
                        {/* FlippableCard */}
                        <FlippableCard
                          flipped={flipped}
                          game={game}
                          card={card}
                          cardIndex={cardIndex}
                          player={player}
                          playerId={playerId}
                        />
                      </>
                    ))}
                  </Box>
                  {/* Player Cards */}
                </Box>

                {/* Player Action UI  */}
                <PlayerActionUI
                  classes={classes}
                  i={i}
                  player={player}
                  game={game}
                />
                {/* Player Action UI */}

                {/* Dealer Chip */}
                <Box
                  className={`${classes.dealerChipContainer} ${
                    classes[`dealer${i + 1}`]
                  }`}
                  sx={{
                    display: player.id === game?.dealerID ? "flex" : "none",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "3vh",
                      fontWeight: "600",
                    }}
                  >
                    D
                  </Typography>
                </Box>
                {/* Dealer Chip */}
              </>
            ))}

            <Box className={classes.community}>
              {game?.gameStarted &&
                game.communityCards.length > 0 &&
                game?.communityCards.map((card, index) => (
                  <>
                    <FlippableCommunityCard game={game} card={card} />
                  </>
                ))}
            </Box>

            {!game?.winner &&
              !game?.advanceToShowDown &&
              !currentPlayerTurn?.isAllIn &&
              currentPlayerTurn?.id === playerId &&
              currentPlayerTurn?.socketID === socket?.id &&
              game?.gameStarted &&
              !game?.isDealing && (
                <>
                  {showRaiseBar && (
                    <Box className={classes.playerRaiseContainer}>
                      <Slider
                        type="raise"
                        minimum={
                          game.currentBet * 2 >= currentPlayerTurn.chips
                            ? currentPlayerTurn.chips
                            : game.currentBet * 2
                        }
                        maximum={currentPlayerTurn.chips}
                        step={game.bigBlind}
                        setSliderAmount={setSliderAmount}
                      />
                    </Box>
                  )}

                  {showBetBar && (
                    <Box className={classes.playerRaiseContainer}>
                      <Slider
                        type="bet"
                        minimum={game.bigBlind}
                        maximum={currentPlayerTurn.chips}
                        step={game.bigBlind}
                        setSliderAmount={setSliderAmount}
                      />
                    </Box>
                  )}

                  <Box className={classes.actionContainer}>
                    <Button
                      style={{
                        backgroundColor: "#e74c3c",
                        color: "white",
                      }}
                      onClick={() => {
                        playSilentAudio();
                        playerAction("fold", 0);
                      }}
                    >
                      Fold
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#3498db",
                        color: "white",
                        display:
                          game.currentBet === 0 ||
                          currentPlayerTurn.currentBet === game.currentBet
                            ? "block"
                            : "none",
                      }}
                      onClick={() => {
                        playSilentAudio();
                        playerAction("check", 0);
                      }}
                    >
                      Check
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "#e67e22",
                        color: "white",
                        display:
                          game.currentBet < currentPlayerTurn.chips &&
                          game.currentBet > 0 &&
                          currentPlayerTurn.currentBet !== game.currentBet
                            ? "block"
                            : "none",
                      }}
                      onClick={() => {
                        playSilentAudio();
                        playerAction("call", 0);
                      }}
                    >
                      Call{" "}
                      {game.currentBet > currentPlayerTurn.currentBet > 0
                        ? formatDollarAmount(
                            game.currentBet - currentPlayerTurn.currentBet
                          )
                        : formatDollarAmount(game.currentBet)}
                    </Button>

                    {showBetBar ? (
                      <Button
                        style={{
                          backgroundColor: "#9b59b6",
                          color: "white",
                        }}
                        onClick={() => {
                          setShowBetBar(false);
                          playSilentAudio();
                          playerAction("bet", sliderAmount);
                        }}
                      >
                        Bet {formatDollarAmount(sliderAmount)}
                      </Button>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: "#9b59b6",
                          color: "white",
                          display: game.currentBet === 0 ? "block" : "none",
                        }}
                        onClick={() => {
                          setShowBetBar(true);
                        }}
                      >
                        Bet
                      </Button>
                    )}

                    {showRaiseBar ? (
                      <Button
                        style={{
                          backgroundColor: "#f1c40f",
                          color: "white",
                        }}
                        onClick={() => {
                          setShowRaiseBar(false);
                          playSilentAudio();
                          playerAction("raise", sliderAmount);
                        }}
                      >
                        Raise {formatDollarAmount(sliderAmount)}
                      </Button>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: "#f1c40f",
                          color: "white",
                          display:
                            game.currentBet < currentPlayerTurn.chips &&
                            game.currentBet > 0
                              ? "block"
                              : "none",
                        }}
                        onClick={() => {
                          setShowRaiseBar(true);
                        }}
                      >
                        Raise
                      </Button>
                    )}

                    <Button
                      style={{
                        backgroundColor: "#3498db",
                        color: "white",
                      }}
                      onClick={() => {
                        playSilentAudio();
                        playerAction("all-in", currentPlayerTurn.chips);
                      }}
                    >
                      All In
                    </Button>
                  </Box>
                </>
              )}

            {game && game?.sidePots.length > 0 && (
              <Box className={classes.potContainer}>
                {game?.sidePots.map((pot, index) => (
                  <Box
                    key={index}
                    className={classes.pot}
                    sx={{
                      display: game?.winner && index === 0 ? "none" : "flex",
                    }}
                  >
                    <img
                      style={{
                        width: "1.5vw",
                      }}
                      src={pokerChip}
                    />
                    <Typography variant="body1" style={{ fontSize: "2vh" }}>
                      {formatDollarAmount(pot?.amount)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            )}

            {/* Animated pot */}
            <animated.div
              className={classes.animatedPot}
              key={game?.sidePots[0]?.amount}
              style={{
                display: game?.winner ? "flex" : "none",
                ...springs,
              }}
            >
              <img style={{ width: "1.5vw" }} src={pokerChip} />
              <Typography variant="body1" style={{ fontSize: "2vh" }}>
                {formatDollarAmount(game?.sidePots[0]?.amount)}
              </Typography>
            </animated.div>
            {/* Animated pot */}

            {game?.winner && (
              <Box className={classes.winnerContainer}>
                <Box className={classes.winner}>
                  <Typography variant="body1" style={{ fontSize: "3vh" }}>
                    <strong>{game?.winner?.names[0]}</strong>
                  </Typography>
                </Box>
                <Box className={classes.winner}>
                  <Typography variant="body1" style={{ fontSize: "3vh" }}>
                    {game?.winner?.handDescription[0]}
                  </Typography>
                </Box>
              </Box>
            )}

            <Button
              style={{
                backgroundColor: "#3498db",
                color: "white",
                position: "absolute",
                top: "5%",
                left: "5%",
                display: doesPlayerExist(game, socket.id) ? "block" : "none",
              }}
              onClick={() => {
                leaveGame();
              }}
            >
              Stand Up
            </Button>

            {/*
            <Box
              sx={{
                position: "absolute",
                top: "15%",
                left: "5%",
                width: "10vw",
              }}
            >
              <Slider
                valueLabelFormat={gameVolumeAmount}
                orientation="horizontal"
                marks={true}
                value={gameVolumeAmount}
                step={0.1}
                min={0}
                max={1.0}
                onChange={(event, newValue) => {
                  Howler.volume(newValue);
                  setGameVolumeAmount(newValue);
                }}
                onChangeCommitted={(event, newValue) => {
                  Howler.volume(newValue);
                  setGameVolumeAmount(newValue);
                }}
                sx={{ color: "#3498db" }}
              />
            </Box>
            */}

            <Button
              style={{
                backgroundColor: "#3498db",
                color: "white",
                position: "absolute",
                top: "5%",
                right: "5%",
                display: doesPlayerExist(game, socket.id) ? "none" : "block",
              }}
              onClick={() => {
                setShowJoinDialog(true);
              }}
            >
              Join Game
            </Button>

            <Button
              style={{
                backgroundColor: "#3498db",
                color: "white",
                position: "absolute",
                top: "18%",
                right: "5%",
                display: playerName === "admin" ? "block" : "none",
              }}
              onClick={() => {
                startGame();
              }}
            >
              Start Game
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  return <>{displayTable()}</>;
}
