import React, { useRef, useEffect } from "react";
import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";
import "./NoUiSlider.css";

const { formatDollarAmount } = require("../../functions/common");

const formatTooltip = (value) => {
  return `${formatDollarAmount(value)}`;
};

const Slider = ({ type, minimum, maximum, step, setSliderAmount }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      noUiSlider.create(sliderRef.current, {
        start: step,
        orientation: "vertical",
        direction: "rtl",
        tooltips: [
          {
            to: formatTooltip,
          },
        ],
        connect: "lower",
        step: step,
        range: {
          min: minimum,
          max: maximum,
        },
        format: {
          to: (value) => value,
          from: (value) => parseInt(value),
        },
      });

      const slider = sliderRef.current.noUiSlider;

      slider.on("update", (values) => {
        const rawValue = slider.options.format.from(values[0]);
        setSliderAmount(rawValue);
      });
    }
  }, []);

  return <div ref={sliderRef} className={`${type}`}></div>;
};

export default Slider;
