import React, { useState } from "react";
import { Box, Card } from "@mui/material";
import { styled } from "@mui/system";

import backofcard from "../assets/cards/back.png";

const { getImageUrl, doesCardExist } = require("../../functions/common");

const FlipCard = styled(Box)(({ flipped }) => ({
  backgroundColor: "transparent",
  display: "flex",
  position: "absolute",
  perspective: "1000px",
  "& .flipCardInner": {
    transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
  },
}));

const FlipCardInner = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  width: "100%",
  height: "100%",
  textAlign: "center",
  transition: "transform 0.6s",
  transformStyle: "preserve-3d",
  top: "0%",
}));

const FlipCardFront = styled(Card)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden",
  backgroundColor: "transparent",
  elevation: 0,
  boxShadow: "none",
}));

const FlipCardBack = styled(Card)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  backfaceVisibility: "hidden",
  backgroundColor: "transparent",
  transform: "rotateY(180deg)",
  elevation: 0,
  boxShadow: "none",
}));

const WinningCards = styled(Card)(({ game, card }) => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundColor: doesCardExist(card, game?.winner?.winningCards)
    ? "#FFFF0030"
    : game?.winner?.winningCards
    ? "#00000050"
    : "transparent",
  border:
    doesCardExist(card, game?.winner?.winningCards) && "0.2vw solid #FFFF00",
  elevation: 0,
  boxShadow: "none",
}));

const FlipCardComponent = ({ game, card }) => {
  const [flipped, setFlipped] = useState(false);

  let cardHeight = "14vh";
  let cardWidth = "5vw";

  useState(() => {
    setTimeout(() => setFlipped(true), 1000);
  }, []);

  return (
    <Box
      textAlign="center"
      style={{
        boxShadow: "none",
      }}
    >
      <FlipCard
        flipped={flipped}
        style={{
          width: cardWidth,
          height: cardHeight,
        }}
      >
        <FlipCardInner className="flipCardInner">
          <FlipCardFront>
            <img
              src={backofcard}
              alt="card"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </FlipCardFront>
          <FlipCardBack>
            <WinningCards game={game} card={card} />
            <img
              src={getImageUrl(card)}
              alt="card"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </FlipCardBack>
        </FlipCardInner>
      </FlipCard>
    </Box>
  );
};

export default FlipCardComponent;
